<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>海外仓营销</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in guojiaList" :key="index"  @click="tabClick(item.id)">
                        <div :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/hwcyx?hzy_id=6'}">外贸营销平台工具</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item >海外仓营销</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details-text">海外仓列表</div>
                    <div class="details-list">
                        <!-- <div class="details-p">海外仓列表<span>（点击海外仓名称，进入对应电商平台官网）:</span></div> -->
                        <div class="details-item-wrap">
                            <div class="details-item" v-for="(item,index) in pingtaiList" :key="index" @click="goTo('haiwai_introduce',{id:item.id})">
                                <div class="details-item-text">{{item.name}}</div>
                                <div class="details-item-p" v-html="delLabel(item.content)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getcountrys,getplatexts} from '../../../api/index';
    export default {
        data() {
            return {
                p_id:'',
                id:'',
                guojiaList:[],
                pingtaiList:[]
            }
        },
        created() {
            this.p_id=this.$route.query.p_id
            this.id=this.$route.query.id
            // console.log(this.$route.query.id)
            this.getcountrys()
            this.getplatexts()
            
        },
        methods: {   
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },  
            // h获取左侧国家分类
            getcountrys(){
                getcountrys({
                    p_id:this.p_id
                }).then((res)=>{
                    // console.log(res);
                    if (res.code==200) {
                        this.guojiaList=res.data;
                    }
                })
            },
            //获取电商平台列表
            getplatexts(){
                getplatexts({
                    plate_id:6,
                    coun_id:this.id
                }).then((res)=>{
                    // console.log(res);
                    if (res.code==200) {
                        this.pingtaiList=res.data.data;
                    }
                })
            },
            tabClick(id){
                this.id=id;
                this.getplatexts();
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 跳转外链
            goUrl(url){
               window.open(url) 
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 0;
                .details-text{color: #555555;font-size: 28px;margin-bottom: 40px;text-align: center;}
                
                .details-list{
                    margin: 50px;
                    .details-p{
                        color: #555555;font-size: 18px;
                        span{font-size: 16px;}
                    }
                    .details-item-wrap{
                        .details-item{
                           margin:40px 0;cursor: pointer;font-size:18px;color:#555555;
                            &:hover{
                                color: #FE6D00;text-decoration:underline;
                            }
                            .details-item-text{
                                font-size: 25px;
                                margin-bottom: 10px;
                            }
                            .details-item-p{
                                font-size: 18px;
                                width: 900px; 
                                white-space: nowrap;                                
                                overflow: hidden;
                                text-overflow:ellipsis;
                            }
                        }
                    }

                }
            }
        }
        
    }

</style>